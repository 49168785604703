export default {
  "formT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire d’inscription"])},
  "formP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat en Marketing digital de la santé"])},
  "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénoms"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "lieu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
  "nationalite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
  "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de résidence"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email valide"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "diplome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplôme obtenu le plus élevé"])},
  "intitule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé du diplôme le plus élevé"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez brièvement vos expériences professionnelles pertinentes pour le programme (300 mots maximum)"])},
  "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre ma candidature"])},
  "candidatureT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature envoyée"])},
  "candidatureP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre candidature a été soumise avec succès. Nous vous contacterons pour confirmer votre pré-sélection."])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitez notre site web"])},
  "buttonbase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler au programme"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifiez-vous en Marketing digital de la santé"])},
  "but1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenez expert(e) du marketing digital en santé avec notre programme à distance de certification professionnelle "])},
  "objectifT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectifs & Compétences visées "])},
  "objectifP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme vous permet d’acquérir les compétences essentielles pour promouvoir avec une approche digitale multicanale, les projets, produits ou services de santé. Il vise à faire de vous, des professionnels et experts de la communication digitale en santé qui pourront valoriser leurs savoirs au sein d'équipes pluridisciplinaires dans les secteurs médicaux, pharmaceutiques, biotechnologiques et humanitaires."])},
  "objectifT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing en ligne"])},
  "objectifP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maîtriser les fondamentaux et outils du marketing digital"])},
  "objectifT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication et santé"])},
  "objectifP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connaître les enjeux de la communication digitale en santé"])},
  "objectifT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécificités sectorielles"])},
  "objectifP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir des stratégies communication digitale adaptées au secteur de la santé"])},
  "objectifT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vulgarisation"])},
  "objectifP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maîtriser les outils et techniques de vulgarisation médico-scientifique"])},
  "objectifT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethique"])},
  "objectifP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connaître les principes éthiques de la communication digitale en santé"])},
  "objectifT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règlementation"])},
  "objectifP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connaître les aspects règlementaires de la communication digitale en santé"])},
  "publicsT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publics cibles & pré-requis"])},
  "publicsT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels de la santé"])},
  "publicsP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecins, chirurgiens, infirmiers, sages-femmes, pharmaciens, paramédicaux."])},
  "publicsT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres publics cibles"])},
  "publicsP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécialiste en communication digitale, chargé de marketing dans les industries de la santé, professionnels de la communication, délégués médicaux, journalistes, gestionnaire de projet, agents de développement, agents communautaires, professionnels du secteur humanitaire, activistes sociaux, acteurs des politiques de santé publique, etc."])},
  "moyensT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyens pédagogiques"])},
  "moyensP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme est basé sur une méthode pédagogique d’approche par compétences et s’insère parfaitement dans votre agenda sans bousculer votre vie professionnelle ou personnelle."])},
  "formatT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
  "formatP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cours sont dispensés à temps partiel, exclusivement à distance, en mode asynchrone (cours pré-enregistrés) et synchrone (classes virtuelles)."])},
  "comiteT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comité"])},
  "comiteP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le comité pédagogique de ce programme est composé d’experts internationaux spécialisés en marketing digital, en marketing de la santé, en droit de la santé et en vulgarisation médico-scientifique."])},
  "dureeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
  "dureeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 heures de cours."])},
  "periodeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
  "periodeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du 1er juin au 10 juillet 2024"])},
  "evaluationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
  "evaluationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la fin du programme, l’étudiant est soumis à une épreuve de contrôle de connaissance en ligne au format QCM."])},
  "certificationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification"])},
  "certificationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le programme est sanctionné par un certificat professionnel accrédité en marketing digital de la santé, qui est délivré aux étudiants ayant obtenu une note supérieure ou égale à 60% au terme du contrôle des connaissances."])},
  "contenuT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu du Programme"])},
  "droitInscriptionT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d’inscription"])},
  "droitInscriptionTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Possibilité de paiements par tranches*"])},
  "droitInscriptionP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les droits d’inscription à la formation s’élèvent à"])},
  "droitInscriptionP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’inscription n’est considérée comme définitive qu’après règlement de ce montant auprès de l’organisme de formation médicale continue REMA, à condition que votre candidature soit acceptée. La date limite pour s’inscrire au programme est le 30 mai 2024. Places limitées !"])},
  "placeDisponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les places disponibles étant limitées pour ce programme de certification, seules les candidatures pertinentes seront retenues. Les candidats sélectionnés seront contactés par nos équipes afin de finaliser leur inscription. Merci."])},
  "produitT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "renseignements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements"])},
  "entrepriseT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "entrepriseP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "entrepriseP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "entrepriseP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
  "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022 REMA. Tous droits réservés."])},
  "confidentialites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialités"])},
  "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])}
}